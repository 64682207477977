@import "/var/www/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.GridList {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  padding: 20px 20px 0px 20px;
}

.GridListItemWrapper {
  margin-bottom: 40px;
}
