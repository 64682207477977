@import "/var/www/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.signInView {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 40px;

  & :global(.card-group) {
    max-width: 700px;
    width: 100%;
  }

  & :global(.card) {
    position: relative;

    opacity: 0;
    animation: fadeIn .5s forwards;
  }

  & :global(.card-body) {
    opacity: 0;
    animation: fadeIn .5s forwards;
  }

  & :global(.card-body).hide {
    opacity: 1;
    animation: fadeOut .5s forwards;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}
