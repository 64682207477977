@import "/var/www/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.Book {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 20px;

  & img {
    width: 188px;
    margin-bottom: 5px;
  }

  & .Title {
    font-weight: 800;
  }

  & .SpanLabel,
  & .Editor,
  & .PublicationDate {
    color: $grey-500;
  }

  & .Editor {
    padding-right: 10px;
    padding-left: 10px;
    width: 188px;
    text-align: center;
  }

  &.Book-lg {
    font-size: 18px;

    & img {
      width: 400px;
      margin-bottom: 20px;
    }

    & .Editor {
      width: 400px;
    }
  }
}
