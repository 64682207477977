@import "/var/www/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.Accordion {
  & .AccordionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $header-color;
    padding: 5px 20px;
    margin-bottom: 20px;

    border: 1px solid $header-color;
    border-radius: 4px;
    cursor: pointer;
    color: #ffffff;
  }


  & .AccordionHeader {
    &.Opened {
      i {
        transform: rotate(90deg);
      }
    }

    &.Disabled {
      pointer-events: none;
      i {
        display: none;
      }
    }
  }

  &.Opened {
    position: relative;
  }
}
