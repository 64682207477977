@import "/var/www/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.EventFormModal {
  min-width: 900px;

  & :global(.modal-content) {
    & > :global(.card) {
      & :global(.card-body) {
        & :global(.form-group),
        & :global(.input-group) {

          & > label {
            flex: 1;
            margin-right: 10px;
          }
        }

        & > :global(.row):not(:first-child) {
          & label {
            min-width: 100px !important;
          }
        }
      }
    }
  }


  & .AuthorsList {
    width: 100%;
    max-height: 120px;
    overflow-y: scroll;
    padding: 5px;

    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    list-style-type: none;
  }

  & .EventData {
    position: relative;
  }
}
