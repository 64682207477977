@import "/var/www/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.BooksellerExternalUsersModal {
  min-width: 900px;

  & > :global(.modal-content) {
    & > :global(.card) {
      margin-bottom: 0;

      & > :global(.card-header) {
        & > :global(.close) {
          margin-left: auto;
        }
      }

      & > :global(.card-footer) {
        display: none;
      }
    }
  }
}
