@import "/var/www/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.UserFormModal {
  min-width: 600px;

  & :global(.modal-content) {
    & > :global(.card) {
      & :global(.card-body) {
        & :global(.form-group),
        & :global(.input-group) {
          & > label {
            max-width: 80px !important;
          }
        }
      }
    }
  }
}
