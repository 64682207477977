@import "/var/www/pde/pde_em_spa/source/src/resources/assets/styles/_variables.scss";
.CoverDetailsPage {
  position: relative;

  & .CoverDetails {
    & .Book {
      float: left;
      margin-right: 40px;
    }

    & .Description {
      padding: 0px 40px 20px 40px;
      text-align: justify;
    }

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;

      & .Book {
        float: none;
        margin-bottom: 20px;
      }
    }
  }

  & .ButtonGroup {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
